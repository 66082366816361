import { batch } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { UserModel } from '../../models/User/UserModel';
import { AppInsightService } from '../../services/AppInsight';
import UserService from '../../services/UserService';
import { setSnackbarData } from '../ducks/layout';
import { setUser } from '../ducks/user';
import { AppState } from '../index';

export class UserEffects {
  static updateUser(userData: Partial<UserModel>) {
    return async (dispatch: ThunkDispatch<AppState, void, Action>, getState: () => AppState) => {
      const userFull = getState().user;
      const updatedUser = { ...userFull, ...userData } as UserModel;

      // set redux for faster update on front
      dispatch(setUser(updatedUser));

      try {
        await UserService.userUpdate(userData);
      } catch (err) {
        console.error(err);
        AppInsightService.trackAppError(err, { data: 'userUpdate()' });
        // Return previous values and show message in case of error
        batch(() => {
          dispatch(setUser(userFull));
          dispatch(
            setSnackbarData({
              isOpened: true,
              message: 'User data was not updated',
              type: 'error'
            })
          );
        });
      }
    };
  }
}
