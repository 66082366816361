import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet-async';

import { devConsole } from '../../utils/DevConsole';
import { MiscUtils } from '../../utils/MiscUtils';
import { PageTypes } from '../constants/Pages';
import { IGame } from '../models/Game/Game';
import { setMobileNavigationUxRedesignedActiveButton } from '../organisms/MobileNavigationUxRedesigned/MobileNavigationUxRedesign.ducks';
import { MobileNavButtonsNames } from '../organisms/MobileNavigationUxRedesigned/MobileNavigationUxRedesigned.types';
import { Analytics } from './Analytics/Analytics';
import { PwaPlatformsMobile } from './Analytics/AnalyticsEventBuilder';
import { LeanplumAnalytics } from './Analytics/LeanplumAnalytics';
import { DeviceDetector, DeviceType } from './DeviceDetector';
import { UrlService } from './UrlService';

export interface PwaManifestConfig {
    name: string;
    shortName: string;
    icon192Url: string;
    icon512Url: string;
    startUrl: string;
}

export type PwaManifest = {
    name: string;
    short_name?: string;
    description?: string;
    background_color: string;
    theme_color: string;
    display: string;
    gcm_sender_id: string;
    gcm_user_visible_only: boolean;
    icons: PwaManifestIcon[];
    start_url: string;
    lang: string;
};

export type PwaManifestIcon = {
    src: string;
    sizes: string;
    type: string;
};

const DEFAULT_MANIFEST = {
    background_color: '#f9f9f9',
    theme_color: '#f9f9f9',
    display: 'standalone',
};

export const LS_GAME_IN_A_ROW_PROP_NAME = 'LS_GAME_IN_A_ROW_PROP_NAME';
const LP_TRIGGER_PWA_PROMO_EVENT = 'LP_TRIGGER_PWA_PROMO_EVENT';

export enum EKycBrowsers {
    IE6 = 'ie6',
    IE8 = 'ie8',
    IE9 = 'ie9',
    IE10 = 'ie10',
    IE11 = 'ie11',
    SAFARI = 'safari',
    CHROME = 'chrome',
    FIREFOX = 'firefox',
    EDGE = 'edge',
    SAMSUNG = 'samsung',
    CHROMIUM = 'chromium',
    OPERA = 'opera',
}

export class PWAService {
    static manifestRelId: string = 'ark-manifest';
    static pwaQueryParam: string = 'fromPwa';
    static pwaLsPropName: string = 'pwa_platform';

    static saveGamesInRowGet(): [string, number] | null {
        if (MiscUtils.isServer) return null;
        try {
            const thisGameInRowLs = (window as any)?.localStorage?.getItem?.(LS_GAME_IN_A_ROW_PROP_NAME);
            if (!thisGameInRowLs) {
                return thisGameInRowLs;
            }
            const thisGameInRowAlias = thisGameInRowLs?.split(',')?.[0];
            const thisGameInRowNumString = thisGameInRowLs?.split(',')?.[1];
            const thisGameInRowNumberParsed = parseInt(thisGameInRowNumString, 10);
            const thisGameInRowNumber = Boolean(thisGameInRowNumberParsed) ? thisGameInRowNumberParsed : null;
            return thisGameInRowAlias && thisGameInRowNumber ? [thisGameInRowAlias, thisGameInRowNumber] : null;
        } catch (e) {
            console.log('PWAService.saveGamesInRowGet', e);
            return null;
        }
    }

    static saveGamesInRowSet(thisGameInRowAlias: string, thisGameInRowNumber: number): void {
        !MiscUtils.isServer &&
            (window as any)?.localStorage?.setItem?.(
                LS_GAME_IN_A_ROW_PROP_NAME,
                `${thisGameInRowAlias},${thisGameInRowNumber}`
            );
    }

    static saveGamesInRowCheck(gameAlias: string): void {
        const gamesInRowRequired = 3;
        const thisGameInRowLs = PWAService.saveGamesInRowGet();
        const [thisGameInRowAlias, thisGameInRowNumber] = thisGameInRowLs || [];
        devConsole('PWAService.saveGamesInRowCheck', thisGameInRowLs, thisGameInRowAlias, thisGameInRowNumber);
        switch (true) {
            case !!thisGameInRowLs &&
                thisGameInRowAlias === gameAlias &&
                thisGameInRowNumber &&
                thisGameInRowNumber === gamesInRowRequired - 1: // otherwise >= will run next time, on 4th game
                PWAService.saveGamesInRowTrigger(gameAlias);
                !MiscUtils.isServer && window?.localStorage?.removeItem?.(LS_GAME_IN_A_ROW_PROP_NAME);
                devConsole('PWAService.saveGamesInRowCheck, case: ', 1);
                break;
            case !!thisGameInRowLs &&
                thisGameInRowAlias === gameAlias &&
                thisGameInRowNumber &&
                thisGameInRowNumber >= gamesInRowRequired:
                PWAService.saveGamesInRowSet(thisGameInRowAlias, thisGameInRowNumber + 1);
                devConsole('PWAService.saveGamesInRowCheck, case: ', 2);
                break;
            case !!thisGameInRowLs && thisGameInRowAlias === gameAlias && !!thisGameInRowNumber:
                PWAService.saveGamesInRowSet(thisGameInRowAlias, thisGameInRowNumber + 1);
                devConsole('PWAService.saveGamesInRowCheck, case: ', 3);
                break;
            case !!thisGameInRowLs && thisGameInRowAlias !== gameAlias:
                PWAService.saveGamesInRowSet(thisGameInRowAlias, 1);
                devConsole('PWAService.saveGamesInRowCheck, case: ', 4);
                break;
            default:
                PWAService.saveGamesInRowSet(gameAlias, 1);
                devConsole('PWAService.saveGamesInRowCheck, case: ', 5);
                break;
        }
    }

    static saveGamesInRowTrigger(gameAlias: string): void {
        devConsole('PWAService.saveGamesInRowTrigger', gameAlias);
        LeanplumAnalytics.trackEvent(LP_TRIGGER_PWA_PROMO_EVENT, { gameAlias }, true);
    }

    static PwaGamesInRowLogic(gameAlias: string): void {
        gameAlias && PWAService.saveGamesInRowCheck(gameAlias);
    }

    static pwaOpenHandle(game: IGame) {
        if (!game) {
            return devConsole('PWAService.handlePwaOpen: ', 'no game', game);
        }
        if (!window.matchMedia('(display-mode: standalone)').matches) {
            return devConsole('PWAService.handlePwaOpen: ', 'not standalone mode', game);
        }
        let pwaOs;
        const pwaPlatformLsSaved = (window as any)?.localStorage?.getItem?.(PWAService.pwaLsPropName);
        const pwaPlatformUrlParam = UrlService.getQSParam(window.location.search, PWAService.pwaQueryParam);
        if (pwaPlatformLsSaved) {
            pwaOs = pwaPlatformLsSaved;
        } else if (pwaPlatformUrlParam) {
            pwaOs = pwaPlatformUrlParam;
        }
        const isPwa = Boolean(pwaOs);
        devConsole('PWAService.handlePwaOpen', game.alias, (window as any).location.search, isPwa, pwaOs, {
            pwaPlatformLsSaved,
            pwaPlatformUrlParam,
        });
        if (!isPwa) {
            devConsole('PWAService.handlePwaOpen', 'not pwa');
            return;
        }
        if (isPwa && !pwaPlatformLsSaved) {
            devConsole('PWAService.handlePwaOpen', 'save pwa platform to ls');
            (window as any)?.localStorage?.setItem?.(PWAService.pwaLsPropName, pwaOs);
        }
        if (isPwa) {
            devConsole('PWAService.handlePwaOpen', 'send analytics', {
                category: PageTypes.Game,
                game,
                platform: pwaOs,
            });
            Analytics.trackEvent(
                Analytics.general.mobileBookmarkPWAVisit({
                    category: PageTypes.Game,
                    game,
                    platform: pwaOs,
                })
            );
        }
        devConsole(
            'PWAService.handlePwaOpen',
            game.alias,
            (window as any).location.search,
            pwaOs,
            isPwa,
            localStorage.getItem(PWAService.pwaLsPropName) || null
        );
    }

    static pwaOpenOs() {
        const pwaPlatformLsSaved = (window as any)?.localStorage?.getItem?.(PWAService.pwaLsPropName);
        const pwaPlatformUrlParam = UrlService.getQSParam(window.location.search, PWAService.pwaQueryParam);
        devConsole('PWAService.pwaOpenOs', pwaPlatformLsSaved, pwaPlatformUrlParam);
        if (pwaPlatformLsSaved && pwaPlatformLsSaved !== PwaPlatformsMobile.OTHER) {
            return pwaPlatformLsSaved;
        }
        if (pwaPlatformUrlParam && pwaPlatformUrlParam !== PwaPlatformsMobile.OTHER) {
            const deviceOs = PWAService.getDeviceOs();
            devConsole('PWAService.pwaOpenOs', 'save pwa platform to ls', deviceOs);
            (window as any)?.localStorage?.setItem?.(PWAService.pwaLsPropName, deviceOs);
            return pwaPlatformUrlParam;
        }
        return false;
    }

    static pwaOpenFact() {
        devConsole('PWAService.pwaOpenFact', PWAService.pwaOpenOs(), !!PWAService.pwaOpenOs());
        return Boolean(PWAService.pwaOpenOs());
    }

    static pwaOpenMobile() {
        const isOpenedPwa = Boolean(PWAService.pwaOpenOs());
        const isStandalone = window?.matchMedia?.('(display-mode: standalone)')?.matches;
        const isMobile = PWAService.getDeviceOs() !== PwaPlatformsMobile.OTHER;
        devConsole(
            'PWAService.pwaOpenMobile',
            PWAService.pwaOpenOs(),
            window?.matchMedia?.('(display-mode: standalone)')
        );
        return isOpenedPwa || (isStandalone && isMobile);
    }

    static getDeviceOs(): PwaPlatformsMobile {
        const userAgent = window.navigator.userAgent || window.navigator.vendor;
        if (/android/gi.test(userAgent)) {
            return PwaPlatformsMobile.ANDROID;
        }
        if (/iPad|iPhone|iPod/gi.test(userAgent) && !(window as any)?.MSStream) {
            return PwaPlatformsMobile.IOS;
        }
        return PwaPlatformsMobile.OTHER;
    }

    static serviceWorkerRegisterScript() {
        const script = `
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('/service-worker.js', {scope: '/'})
                    .then(registration => {
                        console.log('Service Worker registered with scope:', registration.scope);
                    })
                    .catch(error => {
                        console.error('Service Worker registration failed:', error);
                    });
            }
        `;
        return (
            <Helmet>
                <script>{script}</script>
            </Helmet>
        );
    }

    static manifestLinkObject({
        game,
        currLang,
        isItGamePage,
        config,
    }: {
        game?: IGame;
        currLang?: string;
        isItGamePage?: boolean;
        config?: PwaManifestConfig;
    }) {
        if (MiscUtils.isServer) return {};
        let manifestUrl = '';
        try {
            const manifest: PwaManifest = {
                ...DEFAULT_MANIFEST,
                name: isItGamePage ? game.shortTitle : config.name,
                ...(isItGamePage ? { short_name: game.name } : {}),
                ...(isItGamePage ? { description: game.description } : {}),
                background_color: '#FFFFFF',
                theme_color: '#FFFFFF',
                display: 'standalone',
                gcm_sender_id: '967337382999',
                gcm_user_visible_only: true,
                icons: isItGamePage
                    ? [
                          {
                              src: `${game.gameAssetOriginUrl}${game.gameThumbPath}`,
                              sizes: '600x600',
                              type: 'image/png',
                          },
                      ]
                    : [
                          {
                              src: `${config.icon192Url}`,
                              sizes: '192x192',
                              type: 'image/png',
                          },
                          {
                              src: `${config.icon512Url}`,
                              sizes: '512x512',
                              type: 'image/png',
                          },
                      ],
                start_url: isItGamePage
                    ? `${window.location.protocol}//${window.location.host}/pwa-start-url/${
                          game.alias
                      }/${PWAService.getDeviceOs()}`
                    : `${window.location.protocol}//${window.location.host}${window.location.pathname.replace(
                          /\/$/gi,
                          ''
                      )}/?${PWAService.pwaQueryParam}=${PWAService.getDeviceOs()}`,
                lang: isItGamePage ? currLang || 'en-US' : 'en-US',
            };
            // Your JSON data as a string
            const manifestJson = JSON.stringify(manifest);
            // Convert the JSON string to a Uint8Array
            const encoder = new TextEncoder();
            const manifestUint8 = encoder.encode(manifestJson);

            // Convert the Uint8Array to a Base64-encoded string
            const manifestBase64 = btoa(String.fromCharCode.apply(null, manifestUint8));

            // URL-encode the Base64-encoded string
            const manifestDatauri = encodeURIComponent(manifestBase64);

            // Construct the data URL
            manifestUrl = `data:application/json;base64,${manifestDatauri}`;
        } catch (e) {
            console.error(e);
        }
        return { id: PWAService.manifestRelId, rel: 'manifest', href: manifestUrl };
    }

    static pwaInstallSupportFx({ isItGamePage, game, currLang }) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useEffect(() => {
            if (isItGamePage) {
                window.addEventListener('beforeinstallprompt', handleAppInstall);
                return window.removeEventListener('beforeinstallprompt', handleAppInstall);
            }

            function handleAppInstall(e) {
                e.preventDefault();
                (window as any).appDeferredPrompt = e;
            }
        }, [isItGamePage, game, currLang]);
    }

    static pwaInstallOpportunity() {
        const deferredPrompt = (window as any)?.deferredPrompt;
        devConsole('@PWA: deferredPrompt', deferredPrompt);
        return !!deferredPrompt;
    }

    static pwaInstallBtnAction({ dispatch }) {
        const deferredPrompt = (window as any)?.deferredPrompt;
        if (deferredPrompt) {
            deferredPrompt?.prompt?.();
            (window as any)?.deferredPrompt?.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('PWA installed');
                }
                dispatch(setMobileNavigationUxRedesignedActiveButton(MobileNavButtonsNames.GAME));
            });
        } else {
        }
    }

    public static getUserVisitPWA(category?: string, game?: IGame, home?: boolean) {
        // console.log('getUserVisitPWA');
        const deviceDetector = new DeviceDetector();
        const deviceType = deviceDetector.DetectDevice();
        // User open link from device home page / desktop
        if (window.matchMedia('(display-mode: standalone)').matches) {
            if (deviceType === DeviceType.DESKTOP) {
                console.log('PWA display-mode is standalone desktop');
                Analytics.trackEvent(
                    Analytics.general.desktopBookmarkPWAVisit({
                        category,
                        game,
                        home,
                    })
                );
            } else {
                console.log('PWA display-mode is standalone android');
                Analytics.trackEvent(
                    Analytics.general.androidBookmarkVisit({
                        category,
                        game,
                        home,
                    })
                );
            }
        }
        // safari
        if ((window as any).navigator.standalone === true) {
            if (deviceType === DeviceType.DESKTOP) {
                console.log('PWA display-mode is standalone desktop safari');
                Analytics.trackEvent(
                    Analytics.general.desktopBookmarkPWAVisit({
                        category,
                        game,
                        home,
                    })
                );
            } else {
                console.log('PWA display-mode is standalone safari');
                Analytics.trackEvent(Analytics.general.IOSBookmarkVisit({ category, game, home }));
            }
        }

        (window as any).addEventListener('beforeinstallprompt', (ev) => {
            console.log('beforeinstallprompt');
            // ev.preventDefault();
            (window as any).deferredPrompt = ev;
            // console.log((window as any).deferredPrompt);
            if (deviceType === DeviceType.DESKTOP) {
                //
            } else {
                Analytics.trackEvent(
                    Analytics.general.androidBookmarkImpression({
                        category,
                        game,
                        home,
                    })
                );
            }
        });

        (window as any).addEventListener('appinstalled', () => {
            if (deviceType === DeviceType.DESKTOP) {
                Analytics.trackEvent(
                    Analytics.general.desktopInstallAdd({
                        category,
                        game,
                        home,
                    })
                );
            } else {
                Analytics.trackEvent(
                    Analytics.general.androidInstallAdd({
                        category,
                        game,
                        home,
                    })
                );
            }
        });
    }

    // TODO: review, remove if no need
    // tslint:disable-next-line: member-ordering
    public static promptPWAInstall = (category?: string, game?: IGame) => {
        const deviceDetector = new DeviceDetector();
        const deviceType = deviceDetector.DetectDevice();

        if ((window as any).deferredPrompt) {
            try {
                (window as any).deferredPrompt.prompt();
            } catch (ex) {
                console.log(ex);
            }

            if (deviceType === DeviceType.DESKTOP) {
                Analytics.trackEvent(
                    Analytics.general.desktopBookmarkPWAClick({
                        category,
                        game,
                    })
                );
            }

            (window as any).deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('PWA User accepted the A2HS prompt');
                    Analytics.trackEvent(
                        Analytics.general.desktopBookmarkPWAInstall({
                            category,
                            game,
                        })
                    );
                } else {
                    console.log('PWA User dismissed the A2HS prompt');
                    Analytics.trackEvent(
                        Analytics.general.desktopBookmarkPWACancel({
                            category,
                            game,
                        })
                    );
                }
                (window as any).deferredPrompt = null;
            });
            return '';
        }
        // PWA not supported or
        // already installed

        console.log('PWA not supported or already installed');
        const { navigator } = window as any;
        let pwaVersion: string;

        if (window.matchMedia('(display-mode: standalone)').matches || navigator.standalone === true) {
            pwaVersion = 'AppVersion';
        } else {
            pwaVersion = 'DragVersion';
        }
        return pwaVersion;
    };

    public static kycBrowser(): EKycBrowsers {
        // had split to IE6/7/8/9, but according to EDGE emulator script starts work on IE9
        let browserType: 'webkit' | 'mozilla' | 'ms' | 'opera' | '';
        let browser: EKycBrowsers;
        // vendor prefix-based browser check
        try {
            const browserCheckBlock = document.createElement('div');
            const browserCheckStyle = document.createElement('style');
            browserCheckStyle.classList.add('crv-kyc-browser-check', 'style');
            browserCheckBlock.classList.add('crv-kyc-browser-check', 'block');
            browserCheckStyle.innerHTML =
                '\n' +
                '.crv-kyc-browser-check.block {\n' +
                ' -webkit-transition-duration: 1s;\n' +
                ' -moz-transition-duration: 2s;\n' +
                ' -ms-transition-duration: 3s;\n' +
                ' -o-transition-duration: 4s;\n' +
                '}\n';
            document.head.appendChild(browserCheckStyle);
            document.body.appendChild(browserCheckBlock);
            const block = document.querySelector('.crv-kyc-browser-check.block');
            const computedCss = window.getComputedStyle(block, null);
            const propValue = computedCss.getPropertyValue('transition-duration');
            if (!!propValue) {
                if (!MiscUtils.isServer) {
                    (window as any).KYC = (window as any).KYC || {};
                }
                switch (propValue) {
                    case '1s':
                        browserType = 'webkit';
                        if (!MiscUtils.isServer) {
                            (window as any).KYC.prefix = '-webkit-';
                        }
                        break;
                    case '2s':
                        browserType = 'mozilla';
                        if (!MiscUtils.isServer) {
                            (window as any).KYC.prefix = '-moz-';
                        }
                        break;
                    case '3s':
                        browserType = 'ms';
                        if (!MiscUtils.isServer) {
                            (window as any).KYC.prefix = '-ms-';
                        }
                        break;
                    case '4s':
                        browserType = 'opera';
                        if (!MiscUtils.isServer) {
                            (window as any).KYC.prefix = '-o, OPERA';
                        }
                        break;
                    default:
                        browserType = '';
                        break;
                }
            }
            const clearCheckElems = document.querySelectorAll('.crv-kyc-browser-check');
            clearCheckElems.forEach(function (element) {
                element?.parentNode?.removeChild?.(element);
            });
            // feature detection is more robust and useful for future render than UA
            if (browserType === 'ms') {
                browser = EKycBrowsers.IE6;
                if (isNativeObj(window.JSON) && isNativeFunc(window.JSON.parse)) {
                    browser = EKycBrowsers.IE8;
                } //IE8 mark
                if (isNativeFunc(window.getComputedStyle)) {
                    browser = EKycBrowsers.IE9;
                } // IE9 mark
                if (isNativeFunc('Worker')) {
                    browser = EKycBrowsers.IE10;
                } // IE10 mark
                if (isNativeObj('Crypto')) {
                    browser = EKycBrowsers.IE11;
                } // IE11 mark
            } else if (browserType === 'mozilla') {
                browser = EKycBrowsers.FIREFOX;
            } else if (browserType === 'webkit') {
                if (isNativeObj('Object', 'entries')) {
                    if (
                        // Samsung mark
                        window.navigator.userAgent.toLowerCase().indexOf('samsungbrowser') !== -1
                    ) {
                        browser = EKycBrowsers.SAMSUNG;
                    } else if (
                        // Edge mark
                        Boolean(window.navigator.userAgent.toLowerCase().match(/edgaU/g))
                    ) {
                        browser = EKycBrowsers.EDGE;
                    } else if (
                        // Safari mark
                        window.navigator.vendor.toLowerCase().indexOf('apple') !== -1
                        // || window.navigator.hasOwnProperty('share')
                    ) {
                        browser = EKycBrowsers.SAFARI;
                    } else if (
                        // Chrome mark
                        window.navigator.vendor.toLowerCase().indexOf('google') !== -1
                        // || window.navigator.hasOwnProperty('xr')
                    ) {
                        browser = EKycBrowsers.CHROME;
                    } else {
                        browser = EKycBrowsers.CHROMIUM;
                    }
                }
            }
        } catch (e) {
            browser = EKycBrowsers.IE6;
            console.warn('CRV.KYC: Browser detection error: ', e);
        } finally {
            return browser;
        }
    }

    public static isNoPwaOnIos() {
        return PWAService.kycIsIos() && !PWAService.kycIsSafari();
    }

    public static kycIsIos(): boolean {
        return PWAService.getDeviceOs() === PwaPlatformsMobile.IOS;
    }

    public static kycIsSafari(): boolean {
        return PWAService.kycBrowser() === EKycBrowsers.SAFARI;
    }
}

if (!MiscUtils.isServer) {
    (window as any).KYC = (window as any)?.KYC || {};
    (window as any).KYC.os = PWAService.getDeviceOs();
    (window as any).KYC.browser = PWAService.kycBrowser();
}
if (
    !MiscUtils.isServer &&
    (window as any).location.search &&
    UrlService.getQSParam(window.location.search, 'alertKYC') === 'true'
) {
    alert(
        `Client KYC: \n ${JSON.stringify((window as any)?.KYC || null, null, 4)} \n\n UA: \n ${
            window.navigator.userAgent
        }, \n\n Vendor: \n ${window.navigator.vendor}`
    );
}

function isNativeFunc(func) {
    try {
        if (window.hasOwnProperty(func) && window[func].toString().indexOf('[native code]') !== -1) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        // ie8-
        if (window[func] !== undefined && window[func].toString().indexOf('[native code]') !== -1) {
            return true;
        } else {
            return false;
        }
    }
}

function isNativeObj(obj, subobj?) {
    try {
        if (
            (window.hasOwnProperty(obj) && !subobj) ||
            (window.hasOwnProperty(obj) && subobj && window[obj].hasOwnProperty(subobj))
        ) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        // ie8-
        if ((window[obj] !== undefined && !subobj) || (window[obj] && subobj && window[obj][subobj] !== undefined)) {
            return true;
        } else {
            return false;
        }
    }
}
