import React, { FC, useEffect, useState } from 'react';

import { PlanDto } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/plan.dto';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styles from './JoinBlock.css';
import { Avatar, AvatarTypes, BorderTypes } from '../../../../../atoms/Avatar/Avatar';
import { SubscriptionPlans } from '../../../../../constants/SubscriptionPlan';
import { Button } from '../../../../../FigmaStyleguide/Button/Button';
import { ArkadiumAdvantageLogo } from '../../../../../FigmaStyleguide/Icons/ArkadiumAdvantageLogo';
import { SubscriptionSource } from '../../../../../models/Subscription/SubscriptionData';
import { AppInsightService } from '../../../../../services/AppInsight';
import PaymentService from '../../../../../services/PaymentService';
import UserService from '../../../../../services/UserService';
import { setSubscriptionPlans, setSubscriptionSource } from '../../../../../store/ducks/subscription/common';
import { SubscriptionActions } from '../PaymentAndSubscription';


type JoinBlockProps = {
  type: SubscriptionActions;
};

export const JoinBlock: FC<JoinBlockProps> = React.memo(({ type }: JoinBlockProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(({ user }) => user);
  const plansData = useSelector(({ subscriptionPlans }) => subscriptionPlans);
  const [plans, setPlans] = useState<PlanDto[]>(plansData || []);
  const userAvatar = user?.avatar || user?.subscriberAvatar?.avatar || '';

  useEffect(() => {
    if (!plansData) {
      getSubscriptionPlans(dispatch, setPlans);
    }
  }, [plansData]);

  function getUnitAmountMonthly(plansArray: PlanDto[]) {
    let result: number;

    plansArray.forEach((plan) => {
      if (plan.code === SubscriptionPlans.ANNUAL) {
        const planFullPrice = plan?.currencies[0]?.unitAmount;
        const planPriceValue = planFullPrice / plan?.intervalLength;

        result = Math.floor(planPriceValue * 100) / 100;
      }
    });
    return result;
  }

  function getCurrency(plansArray: PlanDto[]) {
    let result = '';

    plansArray.forEach((item) => {
      if (item.code === SubscriptionPlans.ANNUAL) {
        result = item.currencies && item.currencies[0].currency === 'USD' ? '$' : '';
      }
    });
    return result;
  }

  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <Avatar
          image={userAvatar}
          selectable={false}
          selected={false}
          size={AvatarTypes.REGULAR_ADVANTAGE}
          onSelect={() => null}
          disabled
          border={borderMapping(type)}
          noFrame
        />
      </div>
      <div className={styles.text}>
        <h2 className={styles.title}>Join Arkadium Advantage</h2>
        <div className={styles.description}>
          <p>
            {t('PLAY_OVER_100_GAMES')}&nbsp;
            {plans && getCurrency(plans)}
            {plans && getUnitAmountMonthly(plans)} a month
          </p>
        </div>
        <Button
          className={styles.btnSubscribe}
          onClick={() => {
            dispatch(setSubscriptionSource(SubscriptionSource.PROFILE));
            UserService.openSubscriptionPanel();
          }}
        >
          Subscribe
        </Button>
      </div>
      <div className={styles.logo}>
        <ArkadiumAdvantageLogo className={styles.logoImg}/>
      </div>
    </div>
  );
});

const borderMapping = (type: SubscriptionActions) => {
  return {
    [SubscriptionActions.NONE]: BorderTypes.DEFAULT,
    [SubscriptionActions.STANDARD]: BorderTypes.GOLD,
    [SubscriptionActions.RENEW]: BorderTypes.GOLD,
    [SubscriptionActions.RESTART]: BorderTypes.DISABLED
  }[type];
};

export function getSubscriptionPlans(dispatch: any, callback?: any) {
  PaymentService.getPlans()
    .then((res) => {
      dispatch(setSubscriptionPlans([res]));

      if (callback) {
        callback(res);
      }
    })
    .catch((err) => {
      console.error(' PaymentService.getPlans()', err);
      AppInsightService.trackAppError(err, { data: 'getPlans()' });
    });
}
